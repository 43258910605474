import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import he from 'he'

import SEO from '../components/seo'
import { LinkedIn } from '../components/icons'

class AboutTemplate extends Component {

  renderImage(image) {
    if (image === null || !image.localFile) return
    return <img src={image.localFile.childImageSharp.original.src} alt={image.alt_text} />
  }

  render() {
    let data = this.props.data.page.acf
    let page = this.props.data.page
    return (
      <>
        <SEO title={he.decode(page.yoast_meta.yoast_wpseo_title)} bodyClass="about" description={page.yoast_meta.yoast_wpseo_metadesc} />
        <section className='about__intro'>
          <div className='about__intro__inner'>
            <Fade bottom distance='40px'>
              <div className='about__intro__half'>
                <h1>{ data.title }</h1>
                { data.content.split('\n').map((t, i) => <p key={i}>{ t }</p>) }
              </div>
            </Fade>
          </div>
          <picture>
            { this.renderImage(data.feature_image) }
          </picture>
        </section>

        { data.introduction && (
          <section className='landing__blurb'>
            <Fade bottom distance='40px'>
              <div className='landing__blurb__intro' dangerouslySetInnerHTML={{__html: data.introduction }} />
            </Fade>
          </section>
        ) }

        <Fade bottom distance='40px'>
          <section className='profile__large'>
            <picture>
              { this.renderImage(data.main_dude.acf.image) }
            </picture>
            <content>
              <h2>{ data.main_dude.post_title }</h2>
              <div>
                <p>{ data.main_dude.acf.position }</p>
                <a href={data.main_dude.acf.linkedin} target='_blank' rel='noopener noreferrer nofollow'><LinkedIn color={'#2d271f'} /></a>
              </div>
              <p>{ data.main_dude.acf.blurb }</p>
            </content>
          </section>
        </Fade>

        <section className='about__keypoints'>
          { data.sectors.map((el, i) => (
            <div key={i}>
              <Fade right distance='40px'>
                <h3>{ el.title }</h3>
              </Fade>
              <Fade right distance='40px' cascade={true}>
                <ul>
                  { el.points.map((item, j) => <li key={j}>{ item.text }</li>) }
                </ul>
              </Fade>
            </div>
          )) }
        </section>

        <section className='about__blurb'>
          <div className='about__blurb__picture'>
            <Fade bottom distance='40px'>
              { this.renderImage(data.color_block.image) }
            </Fade>
            <p>{ data.color_block.extra_text }</p>
          </div>
          <Fade top distance='40px'>
            <div className='about__blurb__content'>
              <div>
                { data.color_block.content.split('\n').map((t, i) => <p key={i}>{ t }</p>) }
              </div>
            </div>
          </Fade>
        </section>

        <section className='profile__team'>
          <Fade cascade={true}>
            { data.team.map((el, i) => (
              <div className='profile__team__member' key={i}>
                { this.renderImage(el.member.acf.image) }
                <h3>{ el.member.post_title }</h3>
                <div>
                  <p>{ el.member.acf.position }</p>
                  <a href={ el.member.acf.linkedin } target='_blank' rel='nofollow noopener noreferrer'><LinkedIn color={'#2d271f'} /></a>
                </div>
                <p>{ el.member.acf.blurb }</p>
              </div>
            )) }
          </Fade>
        </section>

        <picture className='about__banner'>
          { this.renderImage(data.banner) }
        </picture>
      </>
    )
  }
}

export default AboutTemplate

export const query = graphql`
  query {
    page: wordpressPage(wordpress_id: {eq: 64}) {
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        title
        content

        feature_image {
          ...original
        }

        introduction

        main_dude {
          post_title
          acf {
            linkedin
            blurb
            position
            image {
              ...original
            }
          }
        }

        sectors {
          title
          points {
            text
          }
        }

        color_block {
          content
          extra_text
          image {
            ...original
          }
        }

        team {
          member {
            post_title
            acf {
              linkedin
              blurb
              position
              image {
                ...original
              }
            }
          }
        }

        banner {
          ...original
        }
      }
    }
  }
`
